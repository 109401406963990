import clsx from 'clsx';
import {
  emptyStateActions,
  emptyStateContainer,
  emptyStateContent,
  emptyStateContentText,
  emptyStateDescription,
  emptyStateHeading,
} from './empty-state.css';

type EmptyStateProps = {
  illustration: React.ReactNode;
  heading: string;
  description?: React.ReactNode;
  action?: React.ReactNode;
  secondaryAction?: React.ReactNode;
  className?: string;
};
export const EmptyState: React.FC<EmptyStateProps> = ({
  illustration,
  heading,
  description,
  action,
  secondaryAction,
  className,
}) => {
  return (
    <div className={clsx(emptyStateContainer, className)}>
      <div className={emptyStateContent}>
        {illustration}
        <div className={emptyStateContentText}>
          <h2 className={emptyStateHeading}>{heading}</h2>
          <div className={emptyStateDescription}>{description}</div>
        </div>
      </div>

      {action || secondaryAction ? (
        <div className={emptyStateActions}>
          {action}
          {secondaryAction}
        </div>
      ) : null}
    </div>
  );
};
